export default function agreement (userInfo, songName, lyricWriter, accompanimentWriter){
  const {name, cardNo, realName} = userInfo
  const date = new Date()
  let identity = '';
  let  dignity = '';
  if(userInfo.webLoginType === 0){
    identity = `身份证号：${cardNo}`
    dignity = `乙方：${realName} （艺名：   ${name}）`
  }else {
    identity = `统一社会信用代码：${userInfo.userCompanyVo.creditCode || ''}`
    dignity = `乙方：${userInfo.userCompanyVo.name || ''}`
  }
  return `
<div>
<p style="margin:0pt; orphans:0; text-align:center; widows:0">
<span style="font-family:宋体; font-size:10.5pt; font-weight:bold">
代售词曲清单</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:Calibri; font-size:10.5pt">
&#xa0;</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:楷体_GB2312; font-size:10.5pt">
甲方：北京海葵科技有限公司  </span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:楷体_GB2312; font-size:10.5pt">
地址：北京市通州区杨庄路天时名苑</span>
<span style="font-family:Calibri; font-size:10.5pt">
12</span>
<span style="font-family:楷体_GB2312; font-size:10.5pt">
号楼</span>
<span style="font-family:Calibri; font-size:10.5pt">
2</span>
<span style="font-family:楷体_GB2312; font-size:10.5pt">
单元</span>
<span style="font-family:Calibri; font-size:10.5pt">
1402</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:Calibri; font-size:10.5pt">
&#xa0;</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:楷体_GB2312; font-size:10.5pt">
${dignity}</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:楷体_GB2312; font-size:10.5pt">
${identity}</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:Calibri; font-size:10.5pt">
&#xa0;</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:楷体_GB2312; font-size:10.5pt">
甲、乙双方于${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日签署了《词曲版权代理销售协议（自由曲库</span>
<span style="font-family:Calibri; font-size:10.5pt">
- </span>
<span style="font-family:楷体_GB2312; font-size:10.5pt">
）》，按照该协议约定，乙方将拥有（或有权代理）完整著作权的词曲作品交由甲方代理销售（以下简称“代售”）。</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:楷体_GB2312; font-size:10.5pt">
经甲乙双方友好协商，以下</span>
<span style="font-family:楷体_GB2312; font-size:10.5pt; text-decoration:underline">  1  </span>
<span style="font-family:楷体_GB2312; font-size:10.5pt">
首音乐作品，乙方交由甲方代理销售。</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:楷体_GB2312; font-size:10.5pt">
说明：</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:Calibri; font-size:10.5pt">
1</span>
<span style="font-family:楷体_GB2312; font-size:10.5pt">
、“词曲作品（</span>
<span style="font-family:Calibri; font-size:10.5pt">
DEMO</span>
<span style="font-family:楷体_GB2312; font-size:10.5pt">
）”体现的作品名称为暂定名，与最终销售协议中体现的作品名称可能不一致。</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:Calibri; font-size:10.5pt">
2</span>
<span style="font-family:楷体_GB2312; font-size:10.5pt">
、“词作者”、“曲作者”填写艺名，没有作者请填“无”。</span>
</p>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:Calibri; font-size:10.5pt">
3</span>
<span style="font-family:楷体_GB2312; font-size:10.5pt">
、本清单包括词曲谱、创作灵感。</span>
</p>
<table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:30.8pt; width:373.45pt">
<tr style="height:23.95pt">
<td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:32.65pt">
<p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
<span style="font-family:楷体; font-size:9pt; font-weight:bold">
序号</span>
</p>
</td>
<td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:109.25pt">
<p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
<span style="font-family:楷体; font-size:9pt; font-weight:bold">
词曲作品（DEMO）名</span>
</p>
</td>
<td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:93.8pt">
<p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
<span style="font-family:楷体; font-size:9pt; font-weight:bold">
词作者</span>
</p>
</td>
<td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:93.8pt">
<p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
<span style="font-family:楷体; font-size:9pt; font-weight:bold">
曲作者</span>
</p>
</td>
</tr>
<tr style="height:29.65pt">
<td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:32.65pt">
<p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; widows:0">
<span style="font-family:楷体; font-size:9pt">1</span>
</p>
</td>
<td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:109.25pt">
<p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
<span style="font-family:楷体; font-size:9pt">${songName}</span>
</p>
</td>
<td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:93.8pt">
<p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
<span style="font-family:楷体; font-size:9pt">${lyricWriter}</span>
</p>
</td>
<td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:93.8pt">
<p style="margin:0pt; orphans:0; text-align:center; widows:0">
<span style="font-family:楷体; font-size:9pt">${accompanimentWriter}</span>
</p>
</td>
</tr>
</table>
<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">

<p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-indent:21pt; widows:0">
<span style="font-family:楷体_GB2312; font-size:10.5pt">
代售区域：全世界</span>
</p>
<p style="margin:0pt; orphans:0; text-align:justify; widows:0">
<span style="font-family:Calibri; font-size:10.5pt">
&#xa0;</span>
</p>
</div>
</div>
`
}
