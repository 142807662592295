<template>
    <div class="result flex-center">
        <img src="../assets/submit-success.png" class="icon" />
        <div class="title">{{title}}</div>
        <div class="desc" v-if="desc">{{desc}}</div>
        <dbutton class="btn" @click="$emit('click')">{{button}}</dbutton>
    </div>
</template>
<script>
export default {
    props:{
        title:String,
        desc: String,
        button: String
    }
}
</script>
<style scoped lang="scss">
.result{
    flex-direction: column;
    .icon{
        width: 105px;
        height: 80px;
    }
    .title{
        margin-top: 40px;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 28px;
    }
    .desc{
        margin-top: 12px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
    }
    .btn{
        margin-top: 24px;
        height: 40px;
        font-size: 14px;
    }
}
</style>