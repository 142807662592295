import Vue from 'vue'
import Message from '@/components/Message.vue'

const message=(message, options) => {
    const Component = Vue.extend(Message)
    const extendComponent = new Component()
    extendComponent.open(message, options)

    document.body.appendChild(extendComponent.$mount().$el)
}

export default message
