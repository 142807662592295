<template>
    <div class="message" v-if="show">
        <div class="icon">
            <img src="../assets/icon-success.png" class="img" />
        </div>
        <div class="content">
            <div class="title">{{title || '提示'}}</div>
            <div class="detail">{{message}}</div>
        </div>
        <img src="../assets/close.png" class="close" @click="show=false" />
    </div>
</template>
<script>
export default {
    data(){
        return {
            show: false,
            title: null,
            message: '',
            type: 'success',
            time: 2000, // 默认显示两秒
        }
    },
    methods:{
        open(message, options = {}){
            this.message = message
            if(options.title){
                this.title = options.title
            }
            if(options.type){
                this.type = options.type
            }
            this.show = true

            setTimeout(() => {
                this.show = false
            }, options.time || this.time)
        }
    }
}
</script>
<style scoped lang="scss">
@keyframes show {
    from{ transform: translateY(-30px); opacity: 0;}
    to{transform: translateY(0px); opacity: 1;}
}
.message{
    position: fixed;
    top: 20px;
    left: calc(50% - 173px);
    width: 326px;
    /* height: 80px; */
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    padding: 16px 20px;
    display: flex;
    animation: show .5s;
    .icon{
        margin-right: 10px;
        .img{
            width: 20px;
            height: 20px;
            display: block;
        }
    }
    .content{
        flex: 1;
        .title{
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
        }
        .detail{
            margin-top: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
        }
    }
    .close{
        width: 10px;
        height: 10px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

}
</style>