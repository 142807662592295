<template>
  <div class="works">
    <left-menu current="demo" />
    <div class="main">
      <h2>词曲DEMO大众投稿</h2>
      <steps
        :step="step"
        :steps="[
          { text: '作品投稿', icon: { active: '01.png', default: '02.png' } },
          { text: '确认协议', icon: { active: '03.png', default: '04.png' } },
          { text: '提交成功', icon: { active: '05.png', default: '06.png' } },
        ]"
      ></steps>

      <el-form
        v-show="step === 1"
        ref="form"
        :model="form"
        :rules="rules"
        :validate-on-rule-change="false"
        label-width="85px"
        label-position="top"
        style="margin-top: 25px"
      >
        <el-row :gutter="40">
          <el-col>
            <el-form-item label="作品类型">
              <el-radio-group v-model="form.type">
                <el-radio :label="0">词曲demo</el-radio>
                <el-radio :label="3">伴奏beat</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item label="歌曲名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>

          <el-col v-show="form.type !== 3">
            <el-form-item label="专辑封面" prop="picturePath">
              <el-upload
                accept="image/jpeg,image/png"
                class="avatar-uploader"
                :action="uploadApi"
                :headers="{ ...headers, fileType: 'image' }"
                :show-file-list="false"
                :on-success="onSuccessCover"
                :before-remove="beforeRemove"
              >
                <img
                  v-if="form.picturePath.length > 0"
                  :src="form.picturePath"
                  class="avatar"
                  alt="cover"
                />
                <img
                  v-else
                  src="../assets/upload-placeholder.png"
                  width="100"
                  height="100"
                  alt="upload-placeholder"
                />
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="音频文件" prop="audioPath">
              <el-upload
                accept="audio/mp3,audio/mpeg,audio/cd ,audio/ogg,audio/asf,audio/wma,audio/mp3pro,audio/rm,audio/real,audio/ape,audio/module,audio/midi,audio/vqf,audio/wav,audio/flac,audio/x-ms-wma,audio/x-m4a,audio/m4a"
                :action="uploadApi"
                :headers="{ ...headers, fileType: 'audio' }"
                :show-file-list="false"
                :on-success="onSuccessAudio"
                :before-upload="beforeAvatarUpload"
                :file-list="audioFile"
              >
                <div class="upload-button" v-show="form.audioPath === ''">
                  <img
                    :src="'../assets/upload-cloud.png'"
                    style="width: 16px; height: 16px"
                    alt=""
                  />
                  &nbsp;&nbsp;上传音频文件
                </div>
                <div v-show="form.audioPath !== ''" class="had-upload">
                  {{ form.audioPath.split("/").reverse()[0] }}
                  <div>
                    <img
                      src="../assets/check-green.png"
                      width="10"
                      height="9"
                      alt="del"
                    />
                    <span>上传成功</span>
                    <img
                      src="../assets/del.png"
                      width="18"
                      height="18"
                      alt="del"
                      @click="form.audioPath = ''"
                    />
                  </div>
                </div>
                <div slot="tip" class="el-upload__tip">只能上传音乐文件</div>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="创作说明" prop="creationPath">
              <el-upload
                accept=".doc,.docx"
                :action="uploadApi"
                :headers="{ ...headers, fileType: 'creation' }"
                :show-file-list="false"
                :on-success="onSuccessAudioDescription"
                :before-remove="beforeRemove"
                :file-list="descriptionFile"
              >
                <div v-show="form.creationPath === ''" class="upload-button">
                  <img
                    :src="'../assets/upload-cloud.png'"
                    style="width: 16px; height: 16px"
                    alt=""
                  />
                  &nbsp;&nbsp;上传说明文件
                </div>
                <div v-show="form.creationPath !== ''" class="had-upload">
                  {{ form.creationPath.split("/").reverse()[0] }}
                  <div>
                    <img
                      src="../assets/check-green.png"
                      width="10"
                      height="9"
                      alt="del"
                    />
                    <span>上传成功</span>
                    <img
                      src="../assets/del.png"
                      width="18"
                      height="18"
                      alt="del"
                      @click="form.creationPath = ''"
                    />
                  </div>
                </div>
                <div slot="tip" class="el-upload__tip">
                  只能上传doc或docx文件，请参考模板提供
                  <el-link
                    href="https://hikoon-animation.oss-cn-beijing.aliyuncs.com/text/%E5%88%9B%E4%BD%9C%E8%AF%B4%E6%98%8E%E6%A8%A1%E6%9D%BF.rar"
                    type="primary"
                    >下载模板</el-link
                  >
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="40" v-if="form.type !== 3">
          <el-col :span="12">
            <el-form-item label="歌词文件" prop="lyricsPath">
              <el-upload
                accept=".txt,.doc,.docx,.lrc"
                :action="uploadApi"
                :headers="{ ...headers, fileType: 'lrc' }"
                :show-file-list="false"
                :on-success="onSuccessLyric"
                :before-remove="beforeRemove"
                :file-list="lyricFile"
              >
                <div v-show="form.lyricsPath === ''" class="upload-button">
                  <img
                    :src="'../assets/upload-cloud.png'"
                    style="width: 16px; height: 16px"
                    alt=""
                  />
                  &nbsp;&nbsp;上传歌词文件
                </div>
                <div v-show="form.lyricsPath !== ''" class="had-upload">
                  {{ form.lyricsPath.split("/").reverse()[0] }}
                  <div>
                    <img
                      src="../assets/check-green.png"
                      width="10"
                      height="9"
                      alt="del"
                    />
                    <span>上传成功</span>
                    <img
                      src="../assets/del.png"
                      width="18"
                      height="18"
                      alt="del"
                      @click="form.lyricsPath = ''"
                    />
                  </div>
                </div>
                <div slot="tip" class="el-upload__tip">只能上传txt/doc/docx/lrc文件</div>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="伴奏文件" prop="opernPath">
              <el-upload
                accept="audio/mp3,audio/mpeg,audio/cd,audio/ogg,audio/asf,audio/wma,audio/mp3pro,audio/rm,audio/real,audio/ape,audio/module,audio/midi,audio/vqf,audio/wav,audio/flac,audio/x-ms-wma,audio/x-m4a,audio/m4a"
                :action="uploadApi"
                :headers="{ ...headers, fileType: 'accompany' }"
                :show-file-list="false"
                :on-success="onSuccessAccompaniment"
                :before-upload="beforeAvatarUpload"
                :file-list="accompanimentFile"
              >
                <div v-show="form.opernPath === ''" class="upload-button">
                  <img
                    :src="'../assets/upload-cloud.png'"
                    style="width: 16px; height: 16px"
                    alt=""
                  />
                  &nbsp;&nbsp;上传伴奏文件
                </div>
                <div v-show="form.opernPath !== ''" class="had-upload">
                  {{ form.opernPath.split("/").reverse()[0] }}
                  <div>
                    <img
                      src="../assets/check-green.png"
                      width="10"
                      height="9"
                      alt="del"
                    />
                    <span>上传成功</span>
                    <img
                      src="../assets/del.png"
                      width="18"
                      height="18"
                      alt="del"
                      @click="form.opernPath = ''"
                    />
                  </div>
                </div>
                <div slot="tip" class="el-upload__tip">只能上传音乐文件</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="副歌开始" prop="startTime">
              <el-input
                v-model="form.startTime"
                placeholder="请输入副歌开始时间"
                type="number"
                min="0"
              >
                <template slot="append">秒</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="副歌结束" prop="endTime">
              <el-input
                v-model="form.endTime"
                placeholder="请输入副歌结束时间"
                type="number"
                min="0"
              >
                <template slot="append">秒</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="歌曲标签" prop="tags">
              <el-select
                v-model="form.tags"
                placeholder="请选择"
                multiple
                style="width: 100%"
                @change="$refs['form'].clearValidate('tags')"
              >
                <el-option-group
                  v-for="group in tagOptions"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="关键字" prop="keyWord">
              <el-input
                v-model="form.keyWord"
                placeholder="如：初恋、美好"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="40" v-if="form.type !== 3">
          <el-col :span="12">
            <el-form-item label="词作者" prop="lyricWriter">
              <el-input
                v-model="form.lyricWriter"
                placeholder="多个作者以+连接，如：大白+小黑"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="期望词价格" prop="lyricPrice">
              <el-input
                v-model="form.lyricPrice"
                placeholder="请输入期望词价格"
                type="number"
                min="0"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
              :label="form.type === 3 ? 'beat作者' : '曲作者'"
              prop="accompanimentWriter"
            >
              <el-input
                v-model="form.accompanimentWriter"
                placeholder="多个作者以+连接，如：大白+小黑"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="期望曲价格" prop="accompanimentPrice">
              <el-input
                v-model="form.accompanimentPrice"
                placeholder="请输入期望曲价格"
                type="number"
                min="0"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-show="form.type === 3" v-if="false">
            <el-form-item label="开放对象" prop="openObject">
              <el-select v-model="form.openObject" style="width: 100%">
                <el-option
                  v-for="item in [
                    { label: '全部', value: 0 },
                    { label: '仅S级', value: 1 },
                    { label: 'A级以上', value: 2 },
                    { label: 'B级以上', value: 3 },
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="介绍">
          <el-input
            type="textarea"
            v-model="form.description"
            placeholder="说点儿什么..."
            :autosize="{ minRows: 3 }"
            maxlength="500"
            show-word-limit
            style="font-size: 14px; line-height: 16px"
          ></el-input>
        </el-form-item>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="活动">

              <el-select
              v-if="songstatus"
                style="width: 100%"
                v-model="value1"
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
                <el-select
                 v-else
                style="width: 100%"
                v-model="value2"
                multiple
                :disabled="true"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item style="text-align: center; margin-top: 64px">
          <el-button :loading="submitbol" @click="onSubmit(true)" class="big-button" type="info"
            >存入草稿</el-button
          >
          <el-button type="primary"  @click="nextStep" class="big-button"
            >提交大众投稿</el-button
          >
        </el-form-item>
      </el-form>

      <div v-show="step === 2">
        <h3>协议内容</h3>
        <div class="agreement-content" v-html="agreement"></div>

        <div style="text-align: center; margin-top: 64px">
          <el-button @click="step = 1" class="big-button">我不同意</el-button>
          <el-button type="primary" @click="onSubmit()" :loading="submitbol" class="big-button"
            >提交大众投稿</el-button
          >
        </div>
      </div>

      <div
        v-show="step === 3"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div class="simple-page submited flex-center" style="margin-top: 76px">
          <result
            title="提交成功"
            desc="我们已经收到您的作品，请耐心等待审核，审核成功将以消息的形式通知您～"
            button="继续投稿作品"
            @click="handleBack()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../components/LeftMenu.vue'
import Steps from "../components/Steps"
import store from "../store"
import message from "../utils/message"
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import result from '../components/Result.vue'
import { Message } from 'element-ui'
import agreement from '../utils/agreement'
import { activity_list } from '../api/index'
export default {
  components: {
    LeftMenu,
    Steps,
    result
  },
  name: "Demo",
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  data () {
    const translateMap = {
      startTime: '副歌开始时间',
      endTime: '副歌结束时间',
      lyricPrice: '期望词价格',
      accompanimentPrice: '期望曲价格',
    }
    const checkBiggerThan0 = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error(translateMap[rule.field] + '必须大于0'))
      } else {
        callback()
      }
    }
    const checkSmallThan999999999 = (rule, value, callback) => {
      if (value > 999999999) {
        callback(new Error(translateMap[rule.field] + '不能大于999999999'))
      } else {
        callback()
      }
    }
    return {
      agreement: '',
      step: 1,
      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload',
      form: {
        openObject: 0,
        type: 0,
        picturePath: '',
        name: '',
        startTime: '',
        endTime: '',
        keyWord: '',
        lyricWriter: '',
        lyricPrice: '',
        accompanimentWriter: '',
        accompanimentPrice: '',
        description: '',
        tags: ['其他'],
        audioPath: '',
        lyricsPath: '',
        opernPath: '',
        creationPath: ''
      },
    //判断是否允许参加活动
      activities:false,
      audioFile: [],
      accompanimentFile: [],
      lyricFile: [],
      descriptionFile: [],
      tagOptions: [],
      rules1: {
        // picturePath: {required: true, message: '请填上传专辑封面', trigger: 'blur'},
        name: { required: true, message: '请填写歌曲名称', trigger: 'blur' },
        audioPath: { required: true, message: '请上传音频文件', trigger: 'blur' },
        lyricsPath: { required: true, message: '请上传歌词文件', trigger: 'blur' },
        tags: { required: true, message: '请选择标签', trigger: 'blur' },
        lyricWriter: { required: true, message: '请填写词作者', trigger: 'blur' },
        accompanimentWriter: { required: true, message: '请填写曲作者', trigger: 'blur' },
        startTime: [
          { validator: checkBiggerThan0, trigger: 'blur' },
          { validator: checkSmallThan999999999, trigger: 'blur' }
        ],
        endTime: [
          { validator: checkBiggerThan0, trigger: 'blur' },
          { validator: checkSmallThan999999999, trigger: 'blur' }
        ],
        lyricPrice: [
          { validator: checkBiggerThan0, trigger: 'blur' },
          { validator: checkSmallThan999999999, trigger: 'blur' },
          {required:true,message:'请输入词价格',trigger:'blur'}
        ],
        accompanimentPrice: [
          { validator: checkBiggerThan0, trigger: 'blur' },
          { validator: checkSmallThan999999999, trigger: 'blur' },
          {required:true,message:'请输入曲价格',trigger:'blur'}
        ]
      },
      rules2: {
        name: { required: true, message: '请填写歌曲名称', trigger: 'blur' },
        audioPath: { required: true, message: '请上传音频文件', trigger: 'blur' },
        tags: { required: true, message: '请选择标签', trigger: 'blur' },
        accompanimentWriter: { required: true, message: '请填写beat作者', trigger: 'blur' },
        openObject: { required: true, trigger: 'blur' },
        startTime: [
          { validator: checkBiggerThan0, trigger: 'blur' },
          { validator: checkSmallThan999999999, trigger: 'blur' }
        ],
        endTime: [
          { validator: checkBiggerThan0, trigger: 'blur' },
          { validator: checkSmallThan999999999, trigger: 'blur' }
        ],
        accompanimentPrice: [
          { validator: checkBiggerThan0, trigger: 'blur' },
          { validator: checkSmallThan999999999, trigger: 'blur' },
          {required:true,message:'请输入曲价格',trigger:'blur'}
        ]
      },
      // 活动类容
      options: [],
       value1: [],
    //  获取全部活动用于词曲demo 状态不为草稿时展示
      options1:[],
      value2: [],
      songstatus:true,
      submitbol:false,
    }
  },
  computed: {
    rules () {
      return this.form.type === 0 ? this.rules1 : this.rules2
    },
    getDemoId () {
      return this.id === '0' ? this.id : this.id.split('-')[0]
    },
    saveDemoId () {
      if (this.id === '0' || this.id.split('-').length <= 1) {
        return undefined;
      } else {
        return this.id.split('-')[0]
      }
    }
  },
  watch: {
    step (value) {
      if (value === 2) this.getUserBaseInfo()
    },
    rules () {
      this.$refs['form'].clearValidate()
    }
  },
  created () {
    // 获取正在经行中活动数据
    // if (this.$route.query.activity) {
     this.huodong()
    if (this.$route.query.activity) {
      //活动添加初始值
      this.value1.push(this.$store.state.Songdetails.id)
    }
  },
  mounted () {
    this.getTags()
    if (this.id !== '0') this.getData()
    else {
      this.form.lyricWriter = this.user.name
      this.form.accompanimentWriter = this.user.name
    }
  },
  methods: {
    huodong(){
         this.options=[]
         this.options1=[]
          let p = { pageNum: 1, pageSize: 30, type: 1 }
      activity_list(p).then(res => {
        res.data.list.forEach(item => {
            this.options1.push(item)
          let type = item.contributionsType.split(',')
          if ((type.length > 1 || type[0] == 1) && item.isSign==0 && item.status==2) {
            this.options.push(item)
          }
        })
      })
    },
    getData () {
      this.$get('/api/demo/detail?songId=' + this.getDemoId).then(r => {
        // todo 字段对齐
        const d = r.data
        const f = this.form
        f.name = d.name
        f.picturePath = d.picturePath || ''
        f.audioPath = d.audioPath || ''
        f.accompanimentWriter = d.composer || ''
        f.creationPath = d.creationPath || ''
        f.lyricWriter = d.lyricist || ''
        f.lyricsPath = d.lyricsPath || ''
        f.lyricPrice = d.lyricsPrice || 0
        f.opernPath = d.opernPath || ''
        f.accompanimentPrice = d.opernPrice || 0
        f.description = d.remark || ''
        f.endTime = d.songEnd || ''
        f.startTime = d.songStart || ''
        f.tags = d.tag ? d.tag.split(',') : []
        f.type = d.type
        f.keyWord = d.keyword || ''
        if(d.totalStatus !=0) this.activities=true;
        // 判断当前状态是否未草稿
        if(d.totalStatus!==0){
           this.songstatus=false;
           this.value2 = d.activityId !=undefined ?d.activityId.split(','):[]
        }
      }).catch(e => {
        console.error(e)
      })
    },
    addIdToTree (tree) {
      let nodes = []
      tree.forEach(r => {
        let node = {}
        node.id = r.label || r.value
        node.label = node.id
        if (r.options && r.options.length > 0) node.children = this.addIdToTree(r.options)
        nodes.push(node)
      })

      return nodes
    },
    getTags () {
      this.$get('/api/demo/tags').then(r => {
        this.tagOptions = r.data
      })
    },
    onSuccessCover (response) {
      if (response.code === 0) {
        this.form.picturePath = response.url
        message('专辑封面上传成功')
      }
    },
    onSuccessAudio (response) {
      if (response.code === 0) {
        this.form.audioPath = response.url
        this.$refs['form'].clearValidate('audioPath')
        message('音频文件上传成功')
      }
    },
    onSuccessAccompaniment (response) {
      if (response.code === 0) {
        this.form.opernPath = response.url
        message('伴奏文件上传成功')
      }
    },
    onSuccessLyric (response) {
      if (response.code === 0) {
        this.form.lyricsPath = response.url
        this.$refs['form'].clearValidate('lyricsPath')
        message('歌词文件上传成功')
      }
    },
    onSuccessAudioDescription (response) {
      if (response.code === 0) {
        this.form.creationPath = response.url
        message('创作说明上传成功')
      }
    },
    nextStep () {
      this.$refs['form'].validate((valid, obj) => {
        if (valid) {
          this.step = 2
        } else {
          Message.error(obj[Object.keys(obj)[0]][0].message)
        }
      })
    },
    onSubmit (isDraft = false) {
    this.submitbol=true
      const p = {
        // todo 字段对齐
        // 音频文件地址
        audioPath: this.form.audioPath,
        // 作曲人
        composer: this.form.accompanimentWriter,
        // 创作说明地址
        creationPath: this.form.creationPath,
        // 曾用名
        formerName: "string",
        // id
        id: this.saveDemoId,
        // 作词人
        lyricist: this.form.lyricWriter,
        // 歌词文件地址
        lyricsPath: this.form.lyricsPath,
        // 做词成本
        lyricsPrice: parseInt(this.form.lyricPrice),
        // 歌曲名称
        name: this.form.name,
        // 曲谱文件地址
        opernPath: this.form.opernPath,
        // 作曲成本
        opernPrice: parseInt(this.form.accompanimentPrice),
        // 相关图片文件地址
        picturePath: this.form.picturePath,
        // 歌曲备注
        remark: this.form.description,
        // 歌副歌结束秒数
        songEnd: parseInt(this.form.endTime),
        // 副歌开始秒数
        songStart: parseInt(this.form.startTime),
        // 歌曲时长(s)
        songTime: 0,
        // 状态 0草稿 1提交审核
        status: isDraft ? 0 : 1,
        // 录入时间
        submitTime: "2021-07-29T03:15:17.052Z",
        // 提交人id
        // submitter: 0,
        // 所属标签
        tag: this.form.tags.join(','),
        // 歌曲类型(0:原创歌曲;1:翻唱歌曲;2:伴奏歌曲
        type: this.form.type,
      //  歌曲关键字
        keyword: this.form.keyWord
      }
       p.activityIds = this.value1.join(',')||''
      this.$post('/api/demo/submit', { ...p }).then(r => {
          this.submitbol=false;
        if (r.code === 0) {
        //   this.$message(isDraft ? '保存草稿成功' : '提交成功')
        this.$popup({msg:isDraft ? '保存草稿成功' : '提交成功', type:'success'})

          this.$refs['form'].resetFields()
          this.form.tags = []

          if (!isDraft) {
            this.step = 3
          } else {
            this.$router.replace('/demo-list')
          }
        }
      }).catch(e => {
        console.error(e)
      })
    },
    beforeRemove (file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleBack () {
         this.value1=[]
         this.step = 1
      if (this.saveDemoId === '0') {
          this.step = 1
      }else {
          this.$router.replace('/demo/0')
          }
          this.songstatus=true;
          this.huodong()
    },
    getUserBaseInfo () {
      this.$post('/api/user/userMsg').then(r => {
        this.agreement = agreement(r.data, this.form.name, this.form.lyricWriter, this.form.accompanimentWriter)
        this.$store.commit('UMSG', r.data)
      })
    },
    beforeAvatarUpload (file) {
      let audioarr = ['audio/mpeg', 'audio/mp3', 'audio/cd', 'audio/ogg', 'audio/asf', 'audio/wma', 'audio/mp3pro', 'audio/rm', 'audio/real', 'audio/ape', 'audio/module', 'audio/midi', 'audio/vqf', 'audio/wav', 'audio/flac', 'audio/x-ms-wma','audio/x-m4a']
      let flag = audioarr.indexOf(file.type)
      if (flag == -1) {
        this.$popup({
          title: '错误',
          msg: '只能上传音频文件',
          type:'error'
        });
        return false;
      } else {
        return true;
      }

    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/works.scss";
@import "../styles/simple-page.scss";
$base-color: #FF004D;
.demo {
  display: flex;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 70px;
}

.main {
  padding: 50px;
  box-sizing: border-box;
}
.el-upload__tip {
  color: #aaaaaa;
  font-size: 10px;
  line-height: 1;
}
.el-link {
  line-height: 1.5;
}

::v-deep .avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
::v-deep .vue-treeselect__control {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
::v-deep .vue-treeselect__control {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  padding: 20px;
}

.agreement-content {
  border: #e8e8e8 solid 1px;
  padding: 4px 20px;
  background-color: #f8f8f8;
  color: #666666;
  max-height: 360px;
  position: relative;
  overflow-y: scroll;
  margin-top: 40px;
}

::v-deep .el-input__inner {
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 0;
}
::v-deep .el-form-item__label{
  font-weight: bold;
  color: #333333;
  line-height: 62px;
}
::v-deep .el-input-group__append{
  background: none;
  border: 0 ;
}
::v-deep .el-upload>button{
  background: #ffe5ed;
  color: $base-color;
  border: none;
  border-radius: 0;
  padding: 12px 32px;
  line-height: 16px;
}
</style>
